import React, { useState } from 'react'
import tw, { css } from 'twin.macro'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Layout from '../components/Layout'
import Menu from '../components/Menu'

import { StyledSection, h1Styles, legalPoliciesParagraph, h5Styles } from '../styles'

const innerSectionStyles = css`
  ${tw`flex flex-col items-center min-h-screen py-40`}

  h2 {
    margin: 1rem 0 0.5rem 0;
  }

  p {
    margin: 1rem 0;
  }

  li {
    margin-left: 1.5rem;
    list-style: auto;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
`

const PrivacyPolicy = ({ location }) => {
  const [showFullMenu, setShowFullMenu] = useState(false)

  const handleMenuAction = () => {
    setShowFullMenu((menuState) => !menuState)
  }

  return (
    <Layout title="Privacy Policy" description="Privacy Policy">
      <Header menuAction={handleMenuAction} />
      <main tw="relative">
        <section css={innerSectionStyles}>
          <StyledSection>
            <h1 css={h1Styles}>Políticas de Privacidad</h1>
            <ol css={legalPoliciesParagraph}>
              <li>
                <h2 css={h5Styles}>Information about data controller</h2>
                <p>Name: SAMY ROAD S.L. ("SAMY ROAD" or "the data controller")</p>
                <p>Address: C. de Ulises, 97, 28043, Madrid</p>
                <p>C.I.F.: B86442852</p>
                <p>
                  Contact: to contact the controller about the use of data processing by SAMY ROAD,
                  the User may contact the Data Protection Officer through the address indicated in
                  this section or email: dpd@samy.com. With the reference (Ref: Data Protection).
                </p>
              </li>
              <li>
                <h2 css={h5Styles}>
                  Information and consent to the processing of personal data on the Website
                </h2>
                <p>
                  Generally, Users using the Web Site do so without providing any personal data.
                  However, in order to access the Services, in certain cases, they must provide
                  certain personal data. SAMY ROAD guarantees the confidentiality of personal data
                  provided by users, as established in the Data Protection regulations.
                  Additionally, in some cases cookies will be installed that may collect personal
                  data, in these cases you will be notified before entering the website, more
                  information about this in the Cookie Policy of the website.
                </p>
                <p>
                  Users are informed that the data they may send to SAMY ROAD will be included into
                  a file owned by them, in order reply to their queries and requests and, if they
                  have consented to do so, send commercial information by telematic means on content
                  and products owned by SAMY ROAD or companies belonging to the same business group.
                </p>
                <ul>
                  Data processing is lawful according to the following basis:
                  <li>User browsing analytics: </li>
                  <li>User’s consent to cookie installation (see Cookies Policy).</li>
                  <li>
                    Sending of commercial communication: User’s consent by filling out the form on
                    the website and checking the box to accept commercial communications.
                  </li>
                </ul>
                <p>
                  In case you have expressly given consent to the sending of commercial
                  communications SAMY ROAD informs the user that you can unsubscribe from such
                  communications, following the instructions that appear at the end of all our
                  emails.
                </p>
                <p>
                  The personal data provided by the User may be communicated to other companies of
                  the SAMY ROAD Group (https://samy.com/en/agencies/) if it is necessary for the
                  aforementioned purposes.
                </p>
                <p>
                  Likewise, the personal data may be communicated to Tax Authorities, Data
                  Protection Control Authorities (e.g. the Spanish Data Protection Agency), State
                  Security Forces and Corps, Judges and Courts if so required or necessary.
                </p>
                <p>
                  The processing described above may involve the international transfer of your data
                  to providers that may reside in third countries or have servers in such locations.
                  In addition to the Commission's adequacy decisions with third countries, SAMY ROAD
                  has ensured that agreements are in place regarding these data transfers and has
                  implemented measures such as standard contractual clauses to guarantee such data
                  flows, thus ensuring compliance with data protection regulations.
                </p>
              </li>
              <li>
                <h2 css={h5Styles}>Security Measures</h2>
                <p>
                  SAMY ROAD informs you that it has implemented the necessary technical and
                  organizational security measures to ensure the security of your personal data and
                  prevent its alteration, loss and treatment and / or unauthorized access, given the
                  state of technology, the nature of the data stored and the risks to which they are
                  exposed, whether from human action or the physical or natural environment. SAMY
                  ROAD constantly maintains the supervision, control and evaluation of processes to
                  ensure respect for data privacy.
                </p>
              </li>

              <li>
                <h2 css={h5Styles}>Data conservation periods</h2>
                <p>
                  In general, SAMY ROAD will retain Users' personal information for the period
                  necessary to fulfill the purposes described in this Privacy Policy, for a maximum
                  period of 6 years.
                </p>
                <p>
                  User’s data of the Website will be kept by SAMY ROAD while they are registered
                  and, once the cancellation is requested, they will be kept for the attention of
                  possible liabilities arising from the treatment until the prescription period of
                  these, at which time, the owners of the Web Site will proceed to its elimination.
                </p>
              </li>
              <li>
                <h2 css={h5Styles}>
                  Exercise of the data subject's rights (access, rectification, opposition, erasure,
                  limitation of processing and, where appropriate, data portability).
                </h2>
                <p>
                  Users may contact SAMY ROAD, in order to exercise their rights of access,
                  rectification, opposition, erasure, limitation of processing and, where
                  appropriate, portability of the data included in their files.
                </p>
                <p>
                  In any case, the user may withdraw consent to the processing of their data at any
                  time, without affecting the lawfulness of the processing based on the consent
                  prior to its withdrawal.
                </p>
                <p>
                  To do so, you must send a letter identified with the reference (Ref. Data
                  Protection) in which you specify the corresponding request to the following
                  address: SAMY ROAD, C. de Ulises, 97, 28043, Madrid. Or by sending identical
                  documentation to the following e-mail address: dpd@samy.com{' '}
                </p>
                <p>
                  To ensure compliance with data protection, the data subject shall have the right
                  to complain to the Spanish Data Protection Agency (AEPD) about SAMY ROAD law
                  violation.
                </p>
              </li>

              <li>
                <h2 css={h5Styles}>Data provided by third parties</h2>
                <p>
                  From SAMY ROAD we ask Users to avoid communicating through any system,
                  application, medium or format, personal data of third parties. However, in the
                  event that the communication made to SAMY ROAD on the Website included personal
                  data by persons who do not hold such data, the User guarantees that he/she has
                  informed such persons of the points contained in the preceding paragraphs and has
                  obtained their consent in compliance with the regulations on data protection.
                  Circumstance that declares to have fulfilled by accepting the present conditions.
                </p>
                <p>
                  The User shall be liable to SAMY ROAD or third parties for any damages that may be
                  caused as a result of the breach of the above statements.
                </p>
              </li>
              <li>
                <h2 css={h5Styles}>Truthfulness, accuracy and updating of data</h2>
                <p>
                  The data provided by the User must be truthful, accurate, complete and updated. In
                  the event of any modification and/or variation in any of the existing data in our
                  database, in particular those relating to contact persons, we kindly request you
                  to inform us of this circumstance in order to update them.
                </p>
              </li>

              <li>
                <h2 css={h5Styles}>Applicable law and jurisdiction</h2>
                <p>
                  The relations established between the User and SAMY ROAD shall be governed by
                  Spanish law. However, for cases in which the regulations provide for the
                  possibility of the parties to submit to a jurisdiction, the User and SAMY ROAD
                  expressly waiving any other jurisdiction that may apply, submit any disputes and /
                  or litigation to the knowledge of the Courts and Tribunals of the city of Madrid.
                </p>
              </li>
            </ol>
          </StyledSection>
        </section>
      </main>
      <Footer />
      <Menu show={showFullMenu} closeMenu={handleMenuAction} location={location} />
    </Layout>
  )
}

export default PrivacyPolicy
